import React from "react"
import { graphql } from 'gatsby'
import LayoutExport from "../components/layouts/layoutExport"
import { Box } from "@chakra-ui/react"
import StaticAdsList from "../components/ad/StaticAdsList"
import ModelAlertSubscribeForm from "../components/Alerts/ModelAlertSubscribeForm";
import Seo from '../components/Seo/';

const ModelTpl = (props) => {
  // console.log(props)

  const {
    name,
    slug,
    refUnivers,
    vipEmailExample,
    contentful_id
  } = props.data.model

  const {
    ads,
  } = props.data


  const { universID } = props.pageContext
  const title = {
    "fr":
      name +
      " " +
      refUnivers.name.toLowerCase() +
      " d'occasion à vendre, achat/vente"
    ,
    "en":
      "Used " +
      name +
      " " +
      refUnivers.name.toLowerCase() +
      " for sale, price/buying/selling a boat"
  };
  return (
    <LayoutExport lang={process.env.GATSBY_LANG}>
      <Seo
        title={
          title[process.env.GATSBY_LANG]
        }
        description={
          name +
          " " +
          refUnivers.name.toLowerCase() +
          " d'occasion et au meilleur prix à vendre en France et en Europe, annonces prix, achat, vente. Wanaboat.fr spécialiste achat/vente " +
          name +
          " d'occasion."
        }
        slug={slug}
      />
      <Box
        maxW="700px"
        mx="auto"
      >
        <Box my="1rem">
          <ModelAlertSubscribeForm
            name={name}
            modelID={contentful_id}
            placeholder={vipEmailExample}
          />
        </Box>
        <StaticAdsList
          ads={ads}
          baseSlug={slug}
          universID={universID}
        />
      </Box>

    </LayoutExport>
  )
}

export default ModelTpl

export const pageQuery = graphql`
  query modelExportQuery($slug: String!, $modelID: String!) {
    model: contentfulModel(id: { eq: $modelID }) {
      contentful_id
      slug
      name
      image
      vipEmailExample
      #childContentfulModelDescriptionRichTextNode {
      #  json
      #}
      #childContentfulModelContentSecondaryRichTextNode {
      #  json
      #}
      refUnivers {
        name
        slug
      }
      refVersions {
        id
        name
        slug
      }
      refBrand {
        name
      }
      modelCharacteristics {
        data {
          key
          value
        }
      }
      refVersions {
        name
        slug
      }
      stolenData {
        name
        mail
        phone
        date
        content
      }
      ad{
        id
        name
        slug
        price
        action
        department
        isBoat
        isPart
        isSail
        isTrolley
        refBrand {
          name
        }
        images {
          url
        }
        user{
          id
        }
        date(formatString: "Y")
        publicationDate(formatString: "D/M/Y")
        refUnivers {
          slug
        }
        location {
          lat
          lon
        }
      }
    }
    ads: allContentfulAd(
      filter: { refModel: { slug: { eq: $slug } } }
      sort: { fields: [publicationDate], order: DESC }
    ) {
      edges {
        node {
          contentful_id
          id
          name
          slug
          price
          department
          isBoat
          isPart
          isSail
          isTrolley
          country
          region
          department

          refBrand {
            name
          }
          pictures { url }

          user{
            id
          }
          date(formatString: "Y")
          publicationDate(formatString: "D/M/Y")
          refUnivers {
            slug
          }
          location {
            lat
            lon
          }
        }
      }
    }
  }
`;